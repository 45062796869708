.project-page-content{
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.project-page-header{
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: end;
    align-items: center;
}
.project-description{
    padding-top:10px;
    width: 50%;
    text-align: center;
}