#github-infobox {
    margin-bottom: 100px;
    min-height: 600px;
}
#github-infobox>h1{
    text-align: center;
}

.github-container {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding-top:20px;
    width: 100%;
}
#github-infobox>h1 {
    padding: 5px;
}
#github-infobox>.row {
    padding: 5px;
}

@media only screen and (max-width: 992px) {
   #github-container>row>col-6{
    width: 100%;
   }
}

.github-calendar{
    justify-content: center;
    display: flex;
}

.recent-repository {
    flex: 1 1 50%;
    margin-top: 10px;
}

.recent-repository>ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.recent-repository>ul>li {
    padding: 5px;
    margin: 0 0px;
}

.repository-title{
    font-size: 22px;
    text-decoration: none;
}

.repository-item {
    padding: 6px;
    min-height: 100px;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;
    border: rgba(0, 0, 0, 0.3) solid 1px
}
.repository-item>p{
    margin: 0;
}

.repository-language{
    padding-top:3px;
    font-size: 12px;
    margin-top:auto;
}

.calendar {
    flex: 0 1 50%;
    display: flex;
    justify-content: space-around;
}

