.header-thumb {
    position: relative;
    width: 100%;
    height: 80vh;
}

@media only screen and (max-width: 992px) {
    .header-thumb {
        position: relative;
        width: 100%;
        height: 30%;
    }
}

.header-thumb-video {
    width: 100%;
    height: 100%;
    font-size: 0;
}

.header-thumb-video>video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.header-thumb-overlay {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: white;
    background-color: rgba(0, 0, 0, 0.4);
}

.header-thumb-controls {
    position: absolute;
    right: 10px;
    bottom: 10px;
    display: flex;
    color: white;
    gap: 10px;
}