html{
  height: 100%;
}
body{
  height: 100%;
}
#root{
  height: 100%;
}

.content{
  padding-top: 20px;
}

.my-container {
  margin: 0 10%;
}

.my-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.my-container-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

}

.my-col-prio {
  /* flex: 0 0 48vw; */
  flex: 0 1;
  margin: auto;
  flex-basis: 50%;
  max-width: 45vw;
  margin: 0 50px;
}
@media only screen and (max-width: 992px) {
  .my-col-prio {
    flex-basis: 90%;
    max-width: 80vw;
  }
}

.my-col {
  flex: 0 1 20%;
  margin: 0 20px;
}

/* body{
  background-color: black;
  color:white;
}

.bg-color-1 {
  background-color:slateblue;
} */

.fade-in {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.5s ease-in;
}