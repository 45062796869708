.video-player-container {
  position: relative;
  /* height: 100%; */
  /* max-width: 640px; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  aspect-ratio: 16/9;
}

.video-player {
  position: relative;
}

.video-player-container>video {
  height: 100%;
  width: 100%;
}

.video-player-controls {
  position: absolute;
  bottom: 5px;
  display: flex;
  color: white;
  gap: 10px;
  z-index: 10;
  width: 100%;
  justify-content: space-between;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 30%, rgba(0, 0, 0, 0) 100%);
}

.sound-controls{
  display: flex;
}

.volume-slider {
  width: 75px;
  height: 4px;
  left: 5px;
  bottom: -46%;
  opacity: 1;
  transition: opacity 0.5s;
}

.volume-slider {
  display: none;
  -webkit-appearance: none;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
  border-radius: 100px;
  position:relative;
}
.on-hover{
  display:block;
}

.volume-icon{
  width: 20px;
}

.volume-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
  border-radius: 100px;
  appearance: none;
  background: black;
  border: solid white 1px;
  cursor: e-resize;
  
}

.volume-slider::-moz-range-thumb {
  width: 10px;
  height: 10px;
  border-radius: 100px;
  background: black;
  border: solid white 1px;
  cursor: e-resize;
}

.misc-controls{
  display: flex;
  gap: 10px;
  padding-right: 5px;
}