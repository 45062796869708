.overview-filter {
    min-height: 450px;
}

.custom-categories {
    display: flex;
    padding: 4px 16px;
    width: 100%;
    box-sizing: border-box;
    flex-wrap: wrap;
    align-items: center;
}

.custom-categories ul {
    text-decoration: none;
    margin-bottom: 0;
    padding: 0;
    list-style: none;
    flex-wrap: wrap;
    display: flex;
    gap: 10px;
}



.search-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.project-categories ul {
    text-decoration: none;
    margin-bottom: 0;
    padding: 0;
    list-style: none;
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    gap: 10px;
}


.project-archive-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
}

.project-categories {
    padding: 10px;
    flex-shrink: 0;
    flex-basis: 200px;
}

.archive-top {
    display: flex;
    justify-content: space-between;
}

.project-archive {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: space-evenly;
    gap: 10px;
}


.single-overview-item {
    display: flex;
    flex-direction: column;
    width: 32%;
    position: relative;
}

@media only screen and (max-width: 992px) {
    .single-overview-item {
        width: 45%;
    }
}

.single-overview-info-container {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    height: 100%;
    width: 100%;
    padding: 5px;
}

.single-overview-info {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-content: space-evenly;
    text-align: center;
}

.single-overview-usp {
    height: 100%;
}

.single-overview-thumbnail {}

.single-overview-thumbnail-container {}

.single-overview-thumbnail-container img {
    aspect-ratio: 16 / 9;
    width: 100%;
}

.single-overview-date-icons {
    align-self: flex-end;
}

.single-overview-categories{
    text-align: right;
}


/*
.single-overview-item{
    display: flex;
    width: 100%;
    position: relative;
    border-top: black 1px solid;
}

.single-overview-thumbnail{

}
.single-overview-thumbnail-container{

}
.overview-filter-single-project{
}
.single-overview-thumbnail-container img{
    width: 140px;
    margin: 4px;
    aspect-ratio: 16 / 9;
}

.single-overview-info-container{
    padding: 4px;
    margin-top: auto;
    flex-grow: 1;
}

.single-overview-projectname{
   font-size: 20px;
   padding-left: 5px;
}

.single-overview-date-icons{
display: flex;
gap: 10px;
}

.single-overview-usp{
    flex-basis: 50%;
}
*/