.featured {
    margin-bottom: 100px;
}


.featured-slider-container {

    position: relative;
}

.featured-slider-nav {
    position: absolute;
    height: 25%;
    vertical-align: middle;
    top: 50%;
    font-size: 50px;
}

.featured-slider-nav>svg {
    vertical-align: middle;
}

.featured-slider-prev {
    transform: translate(-50%, -50%);
    left: -15px;
}

.featured-slider-next {
    transform: translate(50%, -50%);
    right: -15px;
}

.featured-pagination {
    position: absolute;
    /* left: 50%; */
    bottom: -40px;
    width: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

}

.featured-pagination-bullet {
    padding: 4px 10px;
    margin: 0 10px;
    border-radius: 10px;

}

.featured-pagination-bullet-active {}


.single-featured-slider {
   /* height: 360px; */
    display: flex;
}

.single-featured-slider-thumbnail {
    height: 100%;
    /* width: 640px; */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.single-featured-slider-thumbnail-trailer {
    position: relative;
    /* height: 100%; */
    /* max-width: 640px; */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    aspect-ratio: 16/9;
}

.single-featured-slider-thumbnail-trailer>video {
    object-fit: fill;
    height: 100%;
    width: 100%;
}

.single-featured-slider-projectname {
    overflow: hidden;
    font-size: 0px;
    line-height: 1.1;
    padding-bottom: 4px;

}

.single-featured-slider-projectname>span {
    display: inline-block;
    vertical-align: middle;
    font-size: 24px;
}

.single-featured-slider .categories {
    font-size: larger;
    line-height: 1.1;
    padding-bottom: 10px;
}

.single-featured-slider-info-box {
    padding: 0 6px;
    width: 36%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.single-featured-slider-usp {
    height: 100%;
    overflow: auto;
    padding-right: 7px;
    line-height: 1.25;
    flex: 1 1 200px;
}

.single-featured-slider-media-box{
    font-size: 0px;
    width: 64%;
}


.single-featured-slider-thumbnail-box {
   
}

.single-featured-slider-info-box-bottom {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 10px;
    flex-wrap: wrap;
}

.single-featured-slider-CTA-box {
    display: flex;
    justify-content: space-between;
}

.single-featured-slider-subthumbnails {
    display: flex;
    flex-wrap: wrap;
    /* gap: 15px 40px; */
}

.single-featured-slider-single-subthumbnail {
    /* height: 80px;
    width: 143px; */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    aspect-ratio: 16/9;
    flex-basis: calc(16.66666666666667%);
}
.single-featured-slider-single-subthumbnail>img{
    width: 100%;
    aspect-ratio: 16/9;
}

.single-featured-slider-info {
    overflow: hidden;
}


.cover-img {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: opacity 0.5s;
    aspect-ratio: 16 / 9;
}

.cover-img.hidden {
    opacity: 0;
    transition: opacity 0.5s;
}

.featured-volume-slider.hidden {
    opacity: 0;
    transition: opacity 0.5s;
}

.featured-volume-slider {
    width: 75px;
    height: 4px;
    position: absolute;
    left: 10px;
    bottom: 10px;
    opacity: 1;
    transition: opacity 0.5s;
}

@media only screen and (max-width: 992px) {
    .single-featured-slider {
        flex-direction: column;
        align-items: center;
    }
    .single-featured-slider-info-box {
        width: 100%;
    }
    .single-featured-slider-media-box{
        width: auto;
    }


}