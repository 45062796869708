.project-blocks{
    width: 80%;
    max-width: 1200px;
}

.project-block{
    padding: 10px 0;
    width: 100%;
}
.inner-block{
    display: flex;
}
.inner-block>.project-block>.project-block-content> *{
    flex-grow: 1;
}

.project-block-content{
    height: 100%;
    display: flex;
    justify-content: center;
}
.project-block-content > *{
    flex-basis: 50%;
    padding: 0 10px;
}
.project-block-content > p{
    text-align: left;
    flex-grow: 1;
}
.project-block>h2{
    text-align: center;
    padding-bottom: 15px;
}

.project-block-content-text{
    display: flex;
    flex-direction: column;
}

.width-limit{
    max-width: 50%;
}
@media only screen and (max-width: 992px) {
    .project-block-content{
        display: unset;
    }
    .inner-block{
        display: unset;
    }
    .width-limit{
        max-width: unset;
    }
}

.image-gallery-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.image-gallery{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.image-container.landscape{
    flex-direction: column;
    display: flex;
    justify-content: space-between;
}

.image-container{
    flex: 45% 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.image-container>img{
    /* width: 100%; */
}
