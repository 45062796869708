.project-logo {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.project-logo-container>img{
   width: 80%;
}

.categories{
    font-size: larger;
    padding-bottom: 10px;
}

