.media-select-container{
    position: fixed;
    background-color: white;
    width: 80%;
    height: 70%;
    left:10%;
    top:20%;
    border: 10px solid black;
    padding: 5%;
    z-index: 100;
}

.image-upload-form{
    display: flex;
    flex-direction: column;
    width: 30%;
}

.select-images-button{
    border: 2px solid black;
    position: relative;
    width: 150px;
    text-align: center;
    background-color: #f8f9fa;
}

.media-archive{
    position:relative;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    overflow: auto;
    height: 75%;
}
.media-item{
    position: relative;
    width: 18%;
}
.selected-indicator{
    position: absolute;
    border: black solid;
    background: rgb(255 255 255 / 41%);
    width: 30px;
    text-align: center;
    height: 30px;
    top: 0;
    right: 0;
}
.media-item>img{
    width: 100%;
}

.media-item>video{
    width: 100%;
}

.media-select-close-button{
    position: absolute;
    top: 0;
    right: 0;
    background-color: #f8f9fa;
    border: 2px solid black;
    width: 30px;
    height: 30px;
    text-align: center;
    cursor:pointer;
}