.blockform-container {
    border: 1px solid black;
    border-radius: 1px;
    padding: 10px;
}

.blockform-option-container{
    position: absolute;
    width: 20%;
    right: 0;
}
.blockform-move-up-button{
    color: #007bff;
    cursor: pointer;
    border: 1px solid #007bff;
}

.blockform-remove-button {
    color: #de0909;
    cursor: pointer;
    border: 1px solid #de0909;
}

.blockform-container textarea {
    width: 100%;
    height: 100px;
    resize:both;
}