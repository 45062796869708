footer{
    width:  100%;
    height: 100px;
    background-color: var(--footer-background-color);
}
.footer-content{
    width: 75vw;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end
}
.footer-developedby{
    margin-top: 50px;
}