/* Full Page Throbber */
.throbber-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100000;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}
.throbber-overlay.disabled {
    animation: fade-out 1s forwards;
}

@keyframes fade-out {
    0% {
        opacity: 1;
        pointer-events: auto; /* Ensure it's still interactable initially */
    }
    99% {
        opacity: 0; /* Fully transparent before hiding */
    }
    100% {
        opacity: 0;
        pointer-events: none; /* Make it non-interactable */
    }
}

.throbber-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
}

.throbber-spinner {
    width: 50px;
    height: 50px;
    border: 5px solid #fff;
    border-top-color: transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}