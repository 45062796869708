:root {
  /* --default-border-radius: 9px; */
  --default-border-radius: 0px;
  --header-background-color: rgba(255, 255, 255,1);
  --footer-background-color: rgba(255, 255, 255,1);
}

.page{
  min-height: 100%;
}

.content {
  background-color: #F9F9F9;
}

.single-featured-slider {
  background-color: #FFFFFF;

}

.single-featured-slider-thumbnail-trailer {
  border-top-left-radius: var(--default-border-radius);
  border-bottom-left-radius: var(--default-border-radius);
}

.single-featured-slider-thumbnail-trailer>img {
  border-top-left-radius: var(--default-border-radius);
  border-bottom-left-radius: var(--default-border-radius);
}

.single-featured-slider-thumbnail-trailer>video {
  border-top-left-radius: var(--default-border-radius);
  border-bottom-left-radius: var(--default-border-radius);
}

.single-featured-slider-info-box {
  /* border: black solid 3px; */
  background-clip: border-box;
  border-left: none;
  border-radius: var(--default-border-radius);
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

@media only screen and (max-width: 992px) {
  .single-featured-slider-info-box {
    /* border-left: black solid 3px; */
    border-top-right-radius: 0px;
    border-bottom-left-radius: var(--default-border-radius);
  }
  .single-featured-slider-thumbnail-trailer {
    border-top-right-radius: var(--default-border-radius);
    border-bottom-left-radius: 0px;
  }
  
  .single-featured-slider-thumbnail-trailer>img {
    border-top-right-radius: var(--default-border-radius);
    border-bottom-left-radius: 0px;
  }
  
  .single-featured-slider-thumbnail-trailer>video {
    border-top-right-radius: var(--default-border-radius);
    border-bottom-left-radius: 0px;
  }
  .single-featured-slider-subthumbnails{
    margin-top: 5px;
  }
}

.icons {
  color: black;
}

.featured-slider-nav {
  background-color: #FFFFFF;
  color: black;
  border: rgba(0, 0, 0, 0.5) solid 1px;
  border-radius: var(--default-border-radius);
}

.featured-pagination-bullet {
  background-color: #FFFFFF;
  color: rgba(0, 0, 0, 0.5);
  border: black solid 1px;
  border-radius: var(--default-border-radius);
}

.featured-pagination-bullet-active {
  background-color: rgba(0, 0, 0, 0.1);
  color: #FFFFFF;
  border: black solid 2px;
  border-radius: var(--default-border-radius);
}

/* .welcome-text {
  background: radial-gradient(circle at 100% 100%, #ffffff 0, #ffffff 3px, transparent 3px) 0% 0%/8px 8px no-repeat,
    radial-gradient(circle at 0 100%, #ffffff 0, #ffffff 3px, transparent 3px) 100% 0%/8px 8px no-repeat,
    radial-gradient(circle at 100% 0, #ffffff 0, #ffffff 3px, transparent 3px) 0% 100%/8px 8px no-repeat,
    radial-gradient(circle at 0 0, #ffffff 0, #ffffff 3px, transparent 3px) 100% 100%/8px 8px no-repeat,
    linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 10px) calc(100% - 16px) no-repeat,
    linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 16px) calc(100% - 10px) no-repeat,
    linear-gradient(0deg, transparent 0%, #000000 100%);
}
.welcome-box{
  border: black solid 3px;
  border-radius: 9px;
  background-color: white;
} */

/* #github-infobox {
  border: black solid 3px;
  border-radius: 9px;
} */
.repository-item {
  border: black solid 1px;
}

.single-category-borderbox {
  box-shadow: 10px 10px rgba(0, 0, 0, 0.5);
  border-radius: var(--default-border-radius);
}

.single-category {
  border-radius: var(--default-border-radius);
}

.repository-item {
  border-radius: var(--default-border-radius);
}

.single-featured-slider-single-subthumbnail.on-hover {
  opacity: 0.8;
}

.featured-volume-slider {
  -webkit-appearance: none;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
  border-radius: 100px;
}

.featured-volume-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
  border-radius: 100px;
  appearance: none;
  background: black;
  border: solid white 1px;
  cursor: e-resize;
}

.featured-volume-slider::-moz-range-thumb {
  width: 10px;
  height: 10px;
  border-radius: 100px;
  background: black;
  border: solid white 1px;
  cursor: e-resize;
}

.single-overview-item {
  /* background:  linear-gradient(180deg, rgb(0, 172, 172) 0%, rgba(0,0,1,1) 100%); */
  color: black;
  /* border-left: 3px black solid; */

}

.project-archive {
  background-color: white;
  /* box-shadow: 4px 0 10px rgb(0 0 0 / 25%); */
  background-clip: border-box;
}

.project-categories {
  background-color: white;
  /* box-shadow: 4px 0 10px rgb(0 0 0 / 25%); */

}

.project-category.category-active {
  border-left: 4px solid orange;
}

.custom-category.category-active {
  border-bottom: 4px solid orange;
}

.project-categories ul {
  background-color: white;
}

.custom-categories {
  background-color: white;
  border-radius: var(--default-border-radius);
  border: black solid 1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom: unset;
}

.single-overview-date-icons .icons {
  color: black;
}

