.project-details {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.project-detail-single {
    display: flex;
    gap: 20px;
    height: 100%;
}

.icontooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.tooltipText {
    visibility: hidden;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 5px 10px;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.2s, visibility 0.2s;
    white-space: nowrap;
}

.icontooltip:hover .tooltipText {
    visibility: visible;
    opacity: 1;
}